import { useLocalize } from "@component-utils/localization"
import { useToast, type ToastParams } from "@component-utils/toasts"

const _avv_toast = (params: ToastParams) => useToast(params)
const _avv_toast_later = (params: ToastParams) => useToast(params, { later: true })

window.avv_toast = _avv_toast
window.avv_toast_later = _avv_toast_later

const _localize = useLocalize()

window.localizeText = _localize

declare global {
  interface Window {
    /** @deprecated Please use `useToast` directly */
    avv_toast: typeof _avv_toast
    /** @deprecated Please use `useToast` directly */
    avv_toast_later: typeof _avv_toast_later
    /** @deprecated Please use `useLocalize` */ 
    localizeText: typeof _localize
  }

  /** @deprecated Please use `useToast` directly */ 
  const avv_toast: typeof _avv_toast
  /** @deprecated Please use `useToast` directly */ 
  const avv_toast_later: typeof _avv_toast_later
  /** @deprecated Please use `useLocalize` */ 
  const localizeText: typeof _localize
}

export default {}
